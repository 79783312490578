/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "variables/_variables.scss";


#root .auth-wrapper .brand-logo .brand-text{
  line-height: 42px;
  font-weight: 400;
}

/*_buttons.scss overwrite */
.btn {
  &.btn-icon {
    &.rounded-circle {
      padding: 0.715rem 0.736rem;
    }
  }
}

/*_palette.scss overwrite */
.btn-light {
  &:focus,
  &:active,
  &.active {
    color: $dark;
    background-color: rgba(#f7f7f7, .2) !important;
  }
}
.btn-outline-light {
  border: 1px solid #f7f7f7 !important;
  background-color: rgba(#f7f7f7, .6);
  color: $gray;
  &.disabled {
    color: rgba($gray, .4);
  }

  &:hover:not(.disabled):not(:disabled) {
    background-color: rgba($dark, .04);
    color: rgba($gray, .6);
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):focus {
    background-color: rgba($color: $gray, $alpha: 0.4);
    color: $gray;
  }
}

/*_palette-gradient.scss overwrite */
#root .bg-gradient-primary, #root .btn-gradient-primary{
  background: linear-gradient(45deg, #825cf7, #d979d5);
  border: none;
}
#root .bg-gradient-secondary, #root .btn-gradient-secondary{
  background: linear-gradient(45deg, #d979d5, #fb6fbb);
  border: none;
}
#root .bg-gradient-success, #root .btn-gradient-success{
  background: linear-gradient(45deg, #0dcb71, #c4fb79);
  border: none;
}
#root .bg-gradient-danger, #root .btn-gradient-danger{
  background: linear-gradient(45deg, #fb6fbb, #ff9085);
  border: none;
}
#root .bg-gradient-warning, #root .btn-gradient-warning{
  background: linear-gradient(135deg, #ff9085, #ffc444);
  border: none;
}
#root .bg-gradient-info, #root .btn-gradient-info{
  background: linear-gradient(135deg, #825cf7, #aa80f9);
  border: none;
}
#root .bg-gradient-dark, #root .btn-gradient-dark{
  background: linear-gradient(135deg, #161d31, #132948);
  border: none;
}


/* _reboot.scss overwrite */
[dir] h6, [dir] .h6, [dir] h5, [dir] .h5, [dir] h4, [dir] .h4, [dir] h3, [dir] .h3, [dir] h2, [dir] .h2, [dir] h1, [dir] .h1{
  font-family: 'Lexend Deca', Helvetica, Arial, serif;
}

[dir] .main-menu .navbar-header .navbar-brand{
  margin-top: 1rem;
}
.main-menu .navbar-header .navbar-brand .brand-text{
  font-weight: 400;
  padding-left: 0.25rem;
  color: #132948;
}
.dark-layout .main-menu .navbar-header .navbar-brand .brand-text {
  color: #feffff;
}

[dir=ltr] .main-menu.menu-light .navigation > li ul .active,
[dir=ltr] .main-menu.menu-light .navigation > li.active > a,
[dir=ltr] .main-menu.menu-dark .navigation > li ul .active,
[dir=ltr] .main-menu.menu-dark .navigation > li.active > a{
  background: linear-gradient(45deg, #825cf7, rgba(217, 121, 213, 1)) !important;
  border: transparent!important;
}


/* app-invoice.scss overwrite */
.invoice-preview .logo-wrapper .invoice-logo, .invoice-edit .logo-wrapper .invoice-logo, .invoice-add .logo-wrapper .invoice-logo{
  margin-left: 0!important;
  line-height: 42px;
  font-weight: 400!important;
  color:#132948!important;
}
/* page-misc overwrite*/
#root .misc-wrapper .brand-logo .brand-text{
  line-height: 42px;
  font-weight: 400;
}