//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$font-family-sans-serif: 'Open Sans', Helvetica, Arial, serif !default;
$font-family-monospace: 'Lexend Deca', Helvetica, Arial, serif !default;

// Your variable overrides
$body-color: #132948;
$primary: #aa80f9; // Changing primary color
$secondary: #d979d5;
$success: #91d451;
$info: #825cf7;
$danger: #FF5E69;
$warning: #ffc444;
$light: #feffff;
$dark: #132948;
$gray: #b8c2cc;
$accent-4: #fb6fbb;
$accent-5: #ff9085;
$accent-6: #2a344f;
$accent-7: #434968;
$accent-8: #ebe9f1;