@import '../core/colors/palette-variables';

.input-range__slider {
    appearance: none;
    background: $primary;
    border: 2px solid $white;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.8rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;
}
.input-range__slider:active {
    transform: scale(1.3);
}
.input-range--disabled .input-range__slider {
    background: $white;
    border: 1px solid $white;
    box-shadow: none;
    transform: none;
}
.input-range__slider-container {
    transition: left 0.3s ease-out;
}
.input-range__label {
    // color: $border-color;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
}
.input-range__label--min, .input-range__label--max {
    bottom: -2.1rem;
    position: absolute;
    z-index: -1;
}
.input-range__label--min {
    left: 0;
}
.input-range__label--max {
    right: 0;
}
.input-range__label--value {
    position: absolute;
    bottom: -2rem;    
}
.input-range__label--value .input-range__label-container {
    color: $white;
}

.input-range__label.input-range__label--value .input-range__label-container {
    left: -50%;
    position: relative;
    background-color: $primary;
    padding: 5px 12px;
    border-radius: 5px;
}


.input-range__label.input-range__label--min .input-range__label-container,
.input-range__label.input-range__label--max .input-range__label-container
{
    left: 0%;
}
.input-range__label--max .input-range__label-container {
    left: 50%;
}
.input-range__track {
    background: rgba($gray, 0.2);
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.6rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
    background: $gray;
}
.input-range__track--background {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
}
.input-range__track--active {
    background: $primary;
}

.input-range--disabled {
    .input-range__track--active {
        background: $gray;
    }
    .input-range__label.input-range__label--value .input-range__label-container {
        background: $gray;
    }
}

.input-range {
    height: 1rem;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
}
