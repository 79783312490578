// Popovers

.popover {
  box-shadow: $popover-box-shadow;
  max-width: 360px;
  // popover header style
  .popover-header {
    @include font-size(1.07rem);
    // border: 1px solid $popover-header-bg;
    background-color: $white;
    color: $headings-color;
  }
  // popover body style
  .popover-body {
    border: 1px solid $popover-border-color;
    border-top-color: $popover-bg;
  }

  &.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
    display: none;
    // top: -5px
  }

  // popover bottom arrow color like header
  &.bs-popover-bottom {
    .popover-arrow:after {
      border-bottom-color: $popover-header-bg;
    }
  }
  
}
// To set arrow border as we have added border to popover-body and popover-header
.bs-popover-top {
  > .popover-arrow {
    &::after {
      bottom: $popover-border-width + 1px;
    }
  }
}
.bs-popover-end {
  > .popover-arrow {
    &::after {
      left: $popover-border-width + 1px;
    }
  }
}
.bs-popover-start {
  > .popover-arrow {
    &::after {
      right: $popover-border-width + 1px;
    }
  }
}
