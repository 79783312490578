@import 'bootstrap-extended/include'; // Bootstrap includes
@import 'components/include'; // Components includes

.border-gray {
  border-color: $theme-dark-border-color;
}

.bg-light-gray {
  background-color: rgba($dark, 0.1);
  color: rgba($black, 0.5);
}

.dark-layout {
  .bg-light-gray {
    background-color: $theme-dark-border-color;
    color: rgba($white, 0.5);
  }
}